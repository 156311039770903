import React from "react"

import Twitter from "mdi-material-ui/Twitter"
import Github from "mdi-material-ui/GithubCircle"
import LinkedIn from "mdi-material-ui/Linkedin"
import Facebook from "mdi-material-ui/Facebook"
import Mastodon from "mdi-material-ui/Mastodon"

import Link from "@material-ui/core/Link"


import { IconButton } from "@material-ui/core"

import styled from "styled-components"



const SocialLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  flex-align: right;
  li {
    :before {
      content: "";
    }
    list-style-type: none;
    padding: 0;
    margin: 0;
    a {
      text-decoration: none;
      box-shadow: none;
      color: white;
    }
  }
`

const SmallLi = styled.li`
  align-self: left;
`

const AvatarLink = props => {
    return (
      <SmallLi>
        <Link href={props.href}>
          <IconButton aria-label={props.label}>{props.children}</IconButton>
        </Link>
      </SmallLi>
    )
  }


export default  () => <SocialLinks>
<AvatarLink label="Github" href="https://github.com/psykar">
  <Github />
</AvatarLink>
<AvatarLink
  label="LinkedIn"
  href="https://linkedin.com/in/psykar"
>
  <LinkedIn />
</AvatarLink>
<AvatarLink
  label="Twitter"
  href="https://twitter.com/psykar"
>
  <Twitter />
</AvatarLink>
<AvatarLink
  label="Facebook"
  href="https://facebook.com/psykar"
>
  <Facebook />
</AvatarLink>
<AvatarLink label="Mastodon" href="https://aus.social/@psykar" rel="me">
  <Mastodon/>
</AvatarLink>
</SocialLinks>
